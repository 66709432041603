import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// Pages
import Home from '@/pages/Home'
import Services from '@/pages/Services'
import About from '@/pages/About'
import ContactForm from '@/pages/Contact'
import PrivacyPolicy from '@/pages/PrivacyPolicy'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactForm
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPolicy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth', }
    }
  }
})

export default router
