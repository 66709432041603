<template>
  <div id="app">
    <div class="wrapper" :class="{'overflow-hidden': $root.isOpen}">
      <MainHeader/>
      <router-view/>
      <MainFooter/>
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
export default {
  name: 'MainContainer',
  components: {
    MainHeader,
    MainFooter
  }
}
</script>

<style lang="scss" type="text/css">
@import 'assets/style/styles';
</style>