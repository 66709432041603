<template>
  <section class="section-description">
    <div class="container">
      <div class="description-wrapper">
        <h2 class="description-title">At the core of BaFutureTech is a passion to drive results. </h2>
        <div class="description-text_wrapper">
          <div class="description-text">
            Meticulously crafting every single process, we take pride in creating tailored solutions
            for each and every business case.
          </div>
          <div class="description-text">
            We're a full-service digital agency that integrates digital marketing,
            technology, branding & analytics to deliver unprecedented results. Our
            strategies are driving customer engagement and are generating
            millions in revenue for our clients EVERY DAY.
          </div>
        </div>
        <router-link class="description-link"
                     :to="{name: 'services'}">
          <div class="btn-gradient-blue-text">Click here to find out how</div>
          <i class="far fa-long-arrow-right description-link-icon btn-gradient-blue-text"></i>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  data () {
    return {}
  }
}
</script>
