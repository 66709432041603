<template>
  <div>
    <Intro/>
    <Description/>
    <Advantages position="vertical"/>
    <Contact/>
    <Interested/>
  </div>
</template>

<script>
import Intro from '@/components/Home/Intro'
import Description from '@/components/Home/Description'
import Advantages from '@/components/Home/Advantages'
import Contact from '@/components/Home/Contact'
import Interested from '@/components/Home/Interested'

export default {
  components: {
    Intro,
    Description,
    Advantages,
    Contact,
    Interested
  }
}
</script>
