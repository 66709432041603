<template>
  <div class="pages-wrapper">
    <section class="section-intro section-intro-contact">
      <div class="container">
        <div class="intro-wrapper">
          <h1 class="title mb-2">Let's Talk!</h1>
          <h2 class="subtitle">We'd like to hear from you.</h2>
        </div>
      </div>
    </section>
    <Form/>
  </div>
</template>

<script>
import Form from '@/components/Contact/Form'

export default {
  components: { Form }
}
</script>
