<template>
  <header class="header" :class="headerClass">
    <div class="container">
      <div class="header-wrapper">
        <router-link :to="{name: 'Home'}"
                     class="logo"
                     exact
        >
          <img src="@/assets/image/BaFutureTech-logo.svg" alt="BaFutureTech-logo" class="logo-img">
        </router-link>
        <div class="burger" :class="{'burger-open': $root.isOpen}" @click="toggleMobileMenu">
          <i class="fas fa-bars fa-2x"></i>
          <i class="fas fa-times fa-2x"></i>
        </div>
        <nav class="header-nav" :class="{'nav-open': $root.isOpen}">
          <ul class="header-nav__list">
            <router-link tag="b-nav-item"
                         class="header-nav__list-item"
                         :class="{'header-nav__list-item-open': $root.isOpen}"
                         :to="{name: 'services'}"
                         exact
                         active-class="active">
              <span @click="closeMobileMenu">Services</span>
            </router-link>
            <router-link tag="b-nav-item"
                         class="header-nav__list-item"
                         :class="{'header-nav__list-item-open': $root.isOpen}"
                         :to="{name: 'about'}"
                         active-class="active">
              <span @click="closeMobileMenu">About</span>
            </router-link>
            <router-link tag="b-nav-item"
                         class="header-nav__list-item"
                         :class="{'header-nav__list-item-open': $root.isOpen}"
                         :to="{name: 'contact'}"
                         active-class="active">
              <span @click="closeMobileMenu">Contact</span>
            </router-link>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>

export default {
  data () {
    return {}
  },
  computed: {
    headerClass () {
      return {
        'header-bg': this.$route.name !== 'Home',
        'min-vh-100': this.$root.isOpen
      }
    }
  }
}
</script>
