import Vue from 'vue'
import store from '@/store'

Vue.mixin({
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMobileMenu () {
      this.$root.isOpen = !this.$root.isOpen
    },
    closeMobileMenu () {
      this.$root.isOpen = false
    }
  },
  computed: {
    storeState () {
      return store.getters
    },
    store () {
      return store
    },
    apiUpl () {
      return process.env.VUE_APP_API_URL ?? '/'
    }
  }
})
