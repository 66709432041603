<template>
  <section class="section-advantages">
    <div class="container">
      <div class="advantages-block">
        <div class="advantages-item"
             :class="'advantages-item__' + this.position">
          <div class="advantages-item__img-wrapper">
            <img src="@/assets/image/card/lightbulb.png" class="advantages-item__img" alt="lightbulb">
          </div>
          <div class="advantages-item__body">
            <h5 class="advantages-item__title">Branding & Creative Design</h5>
            <div class="advantages-item__text">
              Perceptions of a brand are
              increasingly driven by how
              they are experienced
              within the digital space. We
              give your product or
              business idea shape. Our
              trademark framework
              positions your products
              and services higher than
              your competitors.
            </div>
          </div>
        </div>

        <div class="advantages-item"
             :class="'advantages-item__' + this.position">
          <div class="advantages-item__img-wrapper">
            <img src="@/assets/image/card/rocket.png" class="advantages-item__img" alt="rocket">
          </div>
          <div class="advantages-item__body">
            <h5 class="advantages-item__title">Digital Marketing</h5>
            <div class="advantages-item__text">
              We use a 360° focus on all
              our projects to create
              personalized, coherent,
              omnichannel strategies.
              This approach leads us to
              create a brand experience
              that attracts your users
              and turns them into
              satisfied customers.
            </div>
          </div>
        </div>

        <div class="advantages-item"
             :class="'advantages-item__' + this.position">
          <div class="advantages-item__img-wrapper">
            <img src="@/assets/image/card/target.png" class="advantages-item__img" alt="target">
          </div>
          <div class="advantages-item__body">
            <h5 class="advantages-item__title">Affiliate Marketing</h5>
            <div class="advantages-item__text">
              Affiliate marketing is the process by which an affiliate earns a commission for marketing another person’s
              or company’s products. The affiliate simply searches for a product they enjoy, then promotes that product
              and earns a piece of the profit from each sale they make. The sales are tracked via affiliate links from
              one website to another.
            </div>
          </div>
        </div>
      </div>

      <div :class="{'d-none': position === 'gorizontal', 'd-flex': position === 'vertical'}"
           class="align-content-center justify-content-center">
        <router-link
            class="btn btn-custom btn-gradient btn-gradient-blue"
            :to="{name: 'services'}"
        >
          <span class="btn-gradient-blue-text">Explore Our Services</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['position']
}
</script>
