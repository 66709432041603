<template>
  <div class="pages-wrapper">
    <section class="section-intro section-intro-services">
      <div class="container">
        <div class="intro-wrapper">
          <h1 class="title">Services</h1>
        </div>
      </div>
    </section>

    <section class="section-description">
      <div class="container">
        <div class="description-wrapper">
          <h2 class="description-title">Growth strategy is today’s marketing currency.</h2>
          <div class="description-text_wrapper">
            <div class="description-text">
              In a progressive hyper-connected marketplace you can’t afford to stand pat, not when
              you’re competing every hour of every day for new business. We help companies evolve
              and grow. BaFutureTech partners with you to bridge the gap of data and marketing
              technology to drive growth – we’ve been there, we share your passion, and we know what
              it takes to create a winning plan that empowers you to achieve your goals.
            </div>
          </div>
        </div>
      </div>
    </section>

    <Advantages position="gorizontal"/>
    <Interested/>
  </div>
</template>

<script>
import Advantages from '@/components/Home/Advantages'
import Interested from '@/components/Home/Interested'

export default {
  components: { Interested, Advantages }
}
</script>
