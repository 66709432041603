<template>
  <div class="pages-wrapper">
    <section class="section-intro section-intro-about">
      <div class="container">
        <div class="intro-wrapper">
          <h1 class="title">About</h1>
        </div>
      </div>
    </section>

    <section class="section-description">
      <div class="container">
        <div class="description-wrapper">
          <h2 class="description-title">We Are BaFutureTech</h2>
          <div class="description-text_wrapper">
            <div class="description-text">
              We are a tight-knit team of experts with experience working both locally and
              internationally with big and small brands alike. We strive to make meaningful
              partnerships with our clients and treat their growth as an extension of our own.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section-values">
      <div class="container">
        <div class="section-values__wrapper">
          <h2 class="section-values__title">Our Values</h2>
          <div class="section-values__text">We are a team of marketing experts, passionate about learning from each
            other and
            sharing our ideas with others. Our mission is to help purpose-driven brands reach their
            advertising potential. We encourage mutual growth, put culture first and always strive to
            make the ideal real. Our relationships are based on trust and mutual support.
          </div>
        </div>
      </div>
    </section>

    <section class="section-advantages">
      <div class="container">
        <div class="advantages-block">
          <div class="advantages-item advantages-item__gorizontal">
            <div class="advantages-item__img-wrapper">
              <img src="@/assets/image/card/lightbulb.png" class="advantages-item__img" alt="lightbulb">
            </div>
            <div class="advantages-item__body">
              <h5 class="advantages-item__title">Developing Partnerships</h5>
              <div class="advantages-item__text">
                We believe we can only truly grow and evolve as a
                team by nurturing
                authentic and caring
                relationships. To create
                flourishing, the plant and
                the soil must nourish each
                other. For this reason, we
                cultivate deep and trusting
                partnerships with our
                clients.
              </div>
            </div>
          </div>

          <div class="advantages-item advantages-item__gorizontal">
            <div class="advantages-item__img-wrapper">
              <img src="@/assets/image/card/rocket.png" class="advantages-item__img" alt="rocket">
            </div>
            <div class="advantages-item__body">
              <h5 class="advantages-item__title">Questioning The Status Quo</h5>
              <div class="advantages-item__text">
                We interrogate the status
                quo and are here to
                advance change. Always
                evolving, we refuse to take
                anything for granted. It is
                through upheaval that new
                growth is encouraged.
              </div>
            </div>
          </div>

          <div class="advantages-item advantages-item__gorizontal">
            <div class="advantages-item__img-wrapper">
              <img src="@/assets/image/card/target.png" class="advantages-item__img" alt="target">
            </div>
            <div class="advantages-item__body">
              <h5 class="advantages-item__title">Accepting Challenges</h5>
              <div class="advantages-item__text">
                We love solving problems
                and welcome obstacles
                because they make us
                more creative in crafting
                solutions. Where others
                see a wall, we see a
                surface to grow on.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Interested/>
  </div>
</template>

<script>
import Interested from '@/components/Home/Interested'

export default {
  components: {
    Interested
  }
}
</script>
