<template>
  <section class="section-form">
    <div class="container">
      <div class="form-wrapper">
        <h3 class="form-title">BaFutureTech Information <br> Technology LLC</h3>
        <div class="row">
          <div class="col-lg-5 mb-5 mb-lg-0">
            <div class="form-adress">
              Company: BaFutureTech Tech. Ltd<br>
              Street: 9 Karpenisiou<br>
              City: Nicosia<br>
              ZIP: 2021<br>
              State: Strovolos<br>
              Country: CY
            </div>
          </div>
          <div class="col-lg-7">
            <div class="form-block">
              <form action="#" method="get">
                <div class="form-group">
                  <div class="input-wrapper" :class="{'input-wrapper-error': errors.name && errors.name.length}">
                    <input required type="text"
                           v-model="form.name"
                           class="form-control form-control-custom"
                           id="name">
                    <label class="custom-label custom-label-transform" for="name">Name*</label>
                    <span
                      v-if="errors.name"
                      class="text-error">
                                        {{ errors.name }}
                                      </span>
                  </div>

                </div>

                <div class="form-group">
                  <div class="input-wrapper" :class="{'input-wrapper-error': errors.email && errors.email.length}">
                    <input required type="email" v-model="form.email" class="form-control form-control-custom"
                           id="email">
                    <label class="custom-label custom-label-transform" for="email">Email address*</label>
                    <span
                      v-if="errors.email"
                      class="text-error">
                                        {{ errors.email }}
                                      </span>
                  </div>

                </div>

                <div class="form-group">
                  <div class="input-wrapper" :class="{'input-wrapper-error': errors.phone && errors.phone.length}">
                    <input required type="phone" v-model="form.phone" class="form-control form-control-custom"
                           id="phone">
                    <label class="custom-label custom-label-transform" for="phone">Phone phone*</label>

                    <span
                      v-if="errors.phone"
                      class="text-error">
                                        {{ errors.phone }}
                                      </span>
                  </div>
                </div>

                <div class="form-group">
                  <div class="input-wrapper" :class="{'input-wrapper-error': errors.message && errors.message.length}">
                    <label class="custom-label custom-label-transform" for="message">Message*</label>
                    <textarea v-model="form.message" required class="form-control form-control-custom" id="message"
                              rows="3"></textarea>

                    <span
                      v-if="errors.message"
                      class="text-error">
                                        {{ errors.message }}
                                      </span>
                  </div>

                </div>

                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input v-model="form.checkbox" type="checkbox" class="custom-control-input custom-control-input2" id="customCheck1">
                    <label class="custom-control-label" for="customCheck1">
                      <router-link
                        target="_blank"
                        :to="{name: 'privacy'}"
                        active-class="active">
                        I have read the data protection declaration and I agree that
                        my personal data will be used to contact me.
                      </router-link>
                      I can revoke my
                      consent at any time by sending an email to
                      <a href="mailto:affiliate@bafuturetech.com">affiliate@bafuturetech.com*</a>
                    </label>

                    <span
                      v-if="errors.checkbox"
                      class="text-error">
                                        {{ errors.checkbox }}
                                      </span>
                  </div>

                </div>

                <button type="button" @click="onSubmit" class="btn btn-custom btn-gradient btn-gradient-blue">
                  <span class="btn-gradient-blue-text">Submit</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { notify } from '@/helpers/notifyHelper'

export default {
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        checkbox: false
      },
      errors: {}
    }
  },
  methods: {
    onSubmit: async function (event) {
      event.preventDefault()
      const {
        name,
        email,
        phone,
        message,
        checkbox
      } = this.form
      const data = {
        name,
        email,
        phone,
        message,
        checkbox
      }
      const errors = this.validationForm()
      if (!Object.keys(errors).length) {
        this.axios.post('/email.php', data)
            .then(response => {
              notify(this, 'success', response.data.message)
              this.clearForm()
            })
        .catch(error => {
          notify(this, 'error', error?.data?.message ?? 'Something went wrong')
        })
      }
    },
    validationForm () {
      this.errors = []
      const {
        name,
        email,
        phone,
        message,
        checkbox
      } = this.form

      if (!name) {
        this.errors.name = 'Please enter your name'
      }

      if (!email) {
        this.errors.email = 'Please enter your email'
      } else if (!this.validEmail(email)) {
        this.errors.email = 'Please enter a valid email'
      }

      if (!phone) {
        this.errors.phone = 'Please enter your phone phone'
      }
      if (!message) {
        this.errors.message = 'Please enter your message'
      }
      if (!checkbox) {
        this.errors.checkbox = 'Please agree to the terms'
      }
      return this.errors
    },
    validEmail (email) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    clearForm () {
      this.form.name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.message = ''
      this.form.checkbox = false
    }
  }
}
</script>
