<template>
<section class="section-home">
  <div class="container">
    <div class="home-wrapper">
      <div class="row">
        <div class="col-lg-6 align-self-center text-lg-left text-center">
          <img src="@/assets/image/circle.png" alt="">

          <span class="we-home">We</span>
        </div>
        <div class="col-lg-6 align-self-end py-3 text-lg-left text-center mt-4 mt-sm-0">
          <div class="home-title">Combine Captivating
            <span class="home-title-gold">Design</span></div>
          <div class="home-subtitle">With Data-Driven Marketing</div>
          <router-link
            class="btn btn-custom btn-white"
            :to="{name: 'services'}"
          >
            Our Services
          </router-link>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>
